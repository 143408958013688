import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/Scene',
    name: 'Scene',
    component: () => import(/* webpackChunkName: "about" */ '../views/Scene.vue')
  },  

  {
    path: '/SceneMusic',
    name: 'SceneMusic',
    component: () => import(/* webpackChunkName: "about" */ '../views/SceneMusic.vue')
  },  

  {
    path: '/Video',
    name: 'Video',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Video.vue')
  },
  {
    path: '/Video360',
    name: 'Video360',
    component: () => import(/* webpackChunkName: "about" */ '../views/Video360.vue')
  },
  {
    path: '/Web',
    name: 'Web',
    component: () => import(/* webpackChunkName: "about" */ '../views/Web.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
