<template>
  <div id="app">

    <keep-alive v-bind:include="liveComps">
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
  export default {
    name: 'app',
    data: () => ({
      liveComps:[]
    }),
    watch: {
      $route (to){
        if (to.name == "Scene") {
          this.liveComps.push("Scene")
        }
        else if (to.name == "Home") {
          this.liveComps = []   
        }
        console.log(this.liveComps)
      }
    }
  }
</script>